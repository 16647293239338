/* Custom styling for the scrolling announcement banner copied from rails app */

/* sets the custom digital font called 'digital' */
@font-face {
  font-family: "digital";
  src: url("/DigitalDisplay.ttf");
}

.digital-display {
  margin-top: 0.5rem;
  height: 2.625rem;
}

.digital-display .digital-display-inner {
  font-family: "digital";
  align-items: stretch;
  height: 40px;
}

.digital-display .digital-display-inner .digital-display-content {
  background: rgba(15, 15, 15, 0.6);
  border-radius: 0.1875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 2.5rem;
  height: 40px;
}
@media screen and (max-width: 767px) {
  .digital-display .digital-display-inner .digital-display-content {
    margin-right: 0;
  }
}

.marquee-message {
  padding-right: 60px;
  font-size: xx-large;
  color: #fff8ef;
  text-shadow: 0 0 0.5em #ffa619, 0 0 0.25em #ffa619, 0 0 0.1em #ffa619;
}

.message-scroller .marquee-message {
  margin-right: 250px;
}

.marquee-container {
  margin-right: 10px;
  overflow: hidden;
  width: 100%;
}

.marquee-container > div {
  align-items: stretch;
  display: flex;
}

.marquee-container > div > div {
  align-items: center;
}

@media screen and (max-width: 767px) {
  .marquee-container {
    margin-right: 0;
  }
  .marquee-container > div {
    width: 600px;
  }
}
