.chat .messages {
  background: white;
  color: black;
  padding: 20px;
  overflow-y: scroll;
  height: 600px;
  max-height: 250px;
}
@media screen and (max-width: 767px) {
  .chat .messages {
    max-height: 250px;
  }
}
@media screen and (max-width: 992px) {
  .chat .messages {
    max-height: 350px;
  }
}
.chat .messages .message {
  background: #84a3c3;
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin-bottom: 10px;
  min-width: 60%;
  display: inline-block;
  position: relative;
}
.chat .messages .message.mine {
  float: right;
  background: indianred;
}
.chat .messages .message .actions-dropdown {
  display: none;
  position: absolute;
  bottom: -95px;
  right: 0px;
  color: white;
  background: grey;
  padding: 10px;
  z-index: 1;
}
.chat .messages .message .actions-dropdown.open {
  display: block;
}
.chat .messages .message .actions-dropdown .action {
  cursor: pointer;
}
.chat .messages .message .actions-dropdown .action:hover {
  color: lightblue;
}
.chat .messages .message .actions-toggle {
  display: inline;
  position: absolute;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
}
.chat .messages .message .actions-toggle:hover {
  color: lightblue;
}
.chat .messages .message .meta {
  font-size: 0.9em;
  font-style: italic;
}
.chat .messages .message .meta .user {
  display: inline;
}
.chat .messages .message .meta .time {
  float: right;
}
.chat .messages .message .text {
  overflow-wrap: anywhere;
  max-width: 90%;
}
.chat .chat-input {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 0;
  border: none;
  border-top: 1px solid lightgrey;
  position: fixed;
  bottom: 0;
}
.chat .chat-input:focus {
  outline: none;
}
